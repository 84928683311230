.result-container.after__exam { background-color: #F5F8F0;height: calc(100vh - 65px);overflow-y: auto; }
@media (min-width: 1700px) {
    .result-container .container { max-width: 1600px; }
}

.gradient__overlay__image { left: -10%; }


  /* Add this to your CSS */
  .img-resize {
    width:  auto !important;
    height: 250px !important;
    object-fit: contain;
  }
  
 /* Add this to your CSS */
.img-setting {
    width: auto !important;
    height: 150px !important;
    object-fit: contain;
    background-color: #fff;
  }
  
.marks__card canvas { width: 100% !important;height: auto !important; }
.charts-para{ font-size: 12px;font-weight: 500;color: #929292; }
  
/* @media (max-width: 1500px) {
    .marks__card canvas { height: 220px !important; }
}
@media (min-width:992px) and (max-width: 1200px) {
    .marks__card canvas { height: 180px !important; }
}
@media (max-width: 991px) {
    .marks__card canvas { height: 300px !important; }
}
@media (max-width: 767px) {
    .marks__card canvas { height: inherit !important; }
} */
 
.table thead th{
    background: #FF495521;color: #545454;padding: .75rem 1.25rem;font-weight: 500;font-size: 1rem;text-transform: uppercase;
}
.table tbody td {
    padding: .875rem 1.25rem;font-weight: 500;font-size: 1rem;color: #908E8E;border: 0;
}
.table-striped > tbody > tr:nth-of-type(2n+1) > * {
    --bs-table-color-type: #fff;
    --bs-table-bg-type: #fff;
}
.table-striped > tbody > tr:nth-of-type(2n+2) > * {
    --bs-table-color-type: #FED42D1C;
    --bs-table-bg-type: #FED42D1C;
}

.answered__table thead th:nth-child(2),
.answered__table tbody td:nth-child(2),
.answered__table thead th:nth-child(3),
.answered__table tbody td:nth-child(3) { text-align: center; }

  

.ans-cont-3{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #FED42D1C;
    padding-left: 5px;
    padding-right: 5px;
}

.ans-cont-3 p{
    margin: 0;
}

.question-container-even{
    background-color: #FFFFFF;
    border-radius: 12px; 
}
 
 
.question-options-container .option__item:nth-child(even) {
    background-color: #FED42D1C; 
}
 
.radio-input {
    display: none;
}
  
.radio-input + label {
    position: relative; 
    cursor: pointer;
    user-select: none;
}
  
.radio-input + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background-color: white;  
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='red' stroke='red' stroke-width='4' d='M24 33a9 9 0 1 0 0-18a9 9 0 0 0 0 18Z'/%3E%3C/svg%3E");
      
}
 
.radio-input:checked + label::before {
    border-color: red;
}
 

.question-result-ans{
    background: #F1FCFF;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.result-para-1{ 
    font-size: 15px; 
    margin-bottom: 0;
}

.result-para-2{ 
    font-size: 15px; 
    color: #3EBE42;
    margin-bottom: 0;
}

.Time-taken-container{ 
    font-size: 15px; 
    color:#FF4955;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.Time-taken-container p{
    margin-bottom: 0;
}
 

.next-quiz-session-container{
    background-color: #FFFFFF;
    border-radius: 10px;
}
  
.optionMerging{
    margin-left: 25px !important;
 }
 .backToQuiz
 {
    padding: 10px 10px;
    margin-bottom: 30px;
    background: black;
    color: white;
    border: none;
    border-radius: 25%;
 }

@media (max-width: 575px) {
    .bottom__curved__icon { max-width: 100px; }
}