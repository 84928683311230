.quiz__page .form-select { height: 3.5rem;padding: .75rem 1.5rem; }
.quiz__page .filter__box .form-select { height: 2.5rem;width: 8rem;padding: .2rem .875rem; }
.quiz__page .filter__box .form-control { height: 2.5rem;padding: .2rem .875rem;border-color: #dee2e6; }
/* .table__scroll { max-width: calc(100% - 5rem); } */
/* pagination */
.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: flex-end; /* Aligns the pagination to the right */
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    padding: 8px 16px;
    text-decoration: none;
    color: var(--blue); /* Blue color for the links */
    border: 1px solid #dee2e6;
    border-radius: 4px;
    cursor: pointer;
    text-transform: capitalize;
}

.pagination li a[aria-disabled="true"] {
    color: #6c757d;
    cursor: not-allowed;
}

.pagination li.active a {
    background-color: var(--blue); /* Blue background for active page */
    color: white;
    border-color: var(--blue);
}

.pagination li a:hover:not([aria-disabled="true"]) {
    background-color: #e9ecef;
    border-color: #dee2e6;
}
/* entry filter apply css */
.entry_css_apply {
    display: flex;
    align-items: center;
    gap: 10px; /* Adds space between the elements */
}

.align-item-center {
    margin: 10px 0; /* Adds margin for spacing around the container */
}

.entry_css_apply p {
    margin: 0; /* Removes default paragraph margin */
}

.entry_css_apply select {
    padding: 5px 10px; /* Adds padding inside the select element */
    border-radius: 4px; /* Rounds the corners */
    border: 1px solid #ccc; /* Adds a border */
    background-color: #fff; /* Sets background color */
    cursor: pointer; /* Changes cursor on hover */
}

.entry_css_apply select:focus {
    outline: none; /* Removes default focus outline */
    border-color: var(--blue); /* Changes border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Adds a shadow on focus */
}

.table__scroll { max-width: calc(100% - 40px);overflow-x: auto; }