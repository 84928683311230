.ins-container{
    background: #f7f7fa;
    color: #484747;
}
.ins-container .btn { font-size: .937rem; }
/* @media screen and (min-width:992px){
    .instructions-top-container{
        margin-left: 280px;
        margin-top: 60px;
    }
  }

@media screen and (max-width:992px){
    .instructions-top-container{
        margin-left: 50px;
        margin-top: 60px;
        margin-right: 50px;
    }
  } */
  
  
/* .instructions-top-container p{
   
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: rgba(72, 71, 71, 1);
    padding-top: 30px;
} */

 
 
.instructions-top-container h1{
   
    font-size: 25px;
    font-weight: 500;
    line-height: 27.5px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    margin-top: 30px;
    margin-bottom: 30px;
}

.instruction-checkbox{
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 1px;
    margin-right: 15px;
}

.instruction-label{ font-size: .937rem;color: rgba(255, 73, 85, 1); }

.instruction-previous-button {  min-width: 145px;  } 
.bottom__info__box {
    background-color: #fff;padding: 1.5rem;margin: 0 -1.5rem -1.5rem -1.5rem;
}