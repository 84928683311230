.notice-board-container {
    width: 80%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #ffffff; /* White background */
    padding: 20px;
    border-radius: 10px; /* Optional: to make the corners rounded */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h4 {
    margin-bottom: 20px;
    color: #00ADEF; /* Blue */
    font-weight: bold;
  }
  
  .notice-list {
    list-style: none;
    padding: 0;
  }
  
  .notice-item {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .notice-date {
    font-weight: bold;
    color: #FF4F5A; /* Red */
  }
  
  .notice-content {
    margin-top: 5px;
    color: #4CAF50; /* Green */
  }

  .notice-title {
    font-weight: bold;
    margin-top: 5px;
    color: #eacf25; /* Green */
  }
  
/* pagination */
.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: flex-end; /* Aligns the pagination to the right */
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    padding: 8px 16px;
    text-decoration: none;
    color: var(--blue); /* Blue color for the links */
    border: 1px solid #dee2e6;
    border-radius: 4px;
    cursor: pointer;
    text-transform: capitalize;
}

.pagination li a[aria-disabled="true"] {
    color: #6c757d;
    cursor: not-allowed;
}

.pagination li.active a {
    background-color: var(--blue); /* Blue background for active page */
    color: white;
    border-color: var(--blue);
}

.pagination li a:hover:not([aria-disabled="true"]) {
    background-color: #e9ecef;
    border-color: #dee2e6;
}
 
  