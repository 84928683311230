/* .resultGraph-top-container{
    width:100vw;
    min-height:100vh;
    background-color: #EBFAFF;
}

.resultGraph-main-container{
    margin-left: 280px;
    padding-top: 100px;
}

@media screen and (max-width:998px){
    .resultGraph-main-container{
        margin-left: 65px;
        margin-right:15px;
    }
} */

/* .resultGraph-container{
    background: #FFFFFF;
    border-radius: 12px;
    margin-right: 20px;
} */

.resultPage .chart canvas { width: 100% !important;height: 320px !important; }
@media (max-width: 1399px) {
    .resultPage .chart canvas { height: 240px !important; } 
}
@media (max-width: 575px) {
    .resultPage .chart canvas { height: 180px !important; } 
}

.dashboard__table thead th:nth-child(1),
.dashboard__table tbody td:nth-child(1) { text-align: center;border-right: 1px solid #ededed; }