.header__wrapper { padding: 8rem 0 5rem 0; }
.bg__image { top: -50%;opacity: .8; }


.hero__blog__thumbnail { height: 400px;object-position: center top; }
.blog__thumbnail { height: 340px;object-position: center top; }

.post__type { color: #ABBBC1; }
.description { color: #686868;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;white-space: normal; }
.link { color: var(--blue); }
.link svg { transition: all .3s ease-in-out; }
.link svg path { transition: all .3s ease-in-out; }
.link:hover svg path { stroke: var(--brand); }
.link:hover svg,.link:focus svg,.link:active svg { transform: translateX(.5rem); }


@media (max-width: 991px) {
    .hero__blog__thumbnail { height: 350px; }
    .blog__thumbnail { height: 220px; }
    .bg__image { top: -20%; }
    .header__wrapper { padding: 8rem 0 2rem 0; }
}

@media (max-width: 575px) {
    .hero__blog__thumbnail { height: 260px; }
    .blog__thumbnail { height: 170px; }
    .header__wrapper { padding: 6rem 0 0rem 0; }
}



 