.icon__box { background-color: #EDF4FF;width: 4rem;height: 4rem; }
.card {background: #FFFFFF;box-shadow: 0px 0px 31px rgba(44, 50, 63, 0.02);border-radius: 15px;border: 0;}
 
.circle_graph canvas { width: 90px !important;height: 90px !important; }


.circular_chart {
  display: block;
  margin: 10px auto;
  width: 120px;
  height: 120px;
}

.circle_bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}
.circle {
  stroke: var(--blue);
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
.percentage { font-size: 10px;text-align: center; }

.steps_history {
  margin-bottom: 0;
  margin-right: 10px;
  width: 60px;
  align-items: baseline;
  display: inline-grid;
}

.activity_feed {
  list-style: none;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
  width: 100%;
}

.feed_item {
  border-left: 2px solid #e4e8eb;
  padding-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.feed_item::before {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: -7px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: var(--blue);
}
.feed_item::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
  width: 7px;
  height: 7px;
  top: 10px;
  left: -4px;
  border-radius: 50px;
}
.teacher_date_list { display: flex;gap: .2rem 1rem;flex-wrap: wrap; }
.teacher_date_list li {
  color: #A098AE;
  font-size: 14px;
}

.activity_btns .btn_info {
  min-width: 151px;
  background: #F4FEFF;
  border: 1px solid #F4FEFF;
  font-size: 14px;
  border-radius: 10px;
}