 
 .user-profile-heading{
    font-size: 30px;
    font-weight: 500; 
}

.profile-label{
    margin-bottom: 10px;
    font-weight: 500; 
    margin-top: 10px;
}

.user-profile-main-container .form-control, .user-profile-main-container .form-select{
    height:50px; 
}

 