.outlet__content { height: calc(100vh - 65px);overflow-y: auto;background-color: #F5F8F0; }
.sidebar-container{ transition: all 0.2s ease-in-out 0s;width: 280px;z-index: 1001;background: var(--white);min-height: 100vh; }

.logo__box { height: 65px; } 
.sidebar__list { padding-top: 2rem; }
.sidebar__list li a { color: #6f6f6f;padding: 0.75rem 1rem;transition: color 0.3s ease;display: flex;align-items: center;position: relative; }
.sidebar__list li a .icon { width: 1.5rem;display: block;text-align: center;margin-right: .875rem; }
.sidebar__list li a svg path{ fill: #6f6f6f; }
.sidebar__list li a:hover { color: var(--dark); }
.sidebar__list li a:hover svg path{ fill: var(--dark); } 
.sidebar__list li .active{ color: var(--blue);background-color: #f0f0f05a; }
.sidebar__list li .active::before{ content: '';width: 5px;height: 100%;display: block;background-color: var(--blue);position: absolute;top: 0%;left: 0%;border-radius: 0 4px 4px 0; }
.sidebar__list li .active svg path{ fill: var(--blue); }

@media (max-width: 1399px) {
  .sidebar-container { width: 240px; }
}
@media (max-width: 1199px) {
  .sidebar-container { position: fixed;top: 0%;left: 0%;transform: translateX(-100%);z-index: 1001; }
  .sidebar-container.menu__open { transform: translateX(0%); }
  .sidebar-container.menu__open::before { content: '';width: calc(100vw - 240px);position: fixed;top: 0%;left: 240px;background-color: #000000c0;height: 100%;z-index: 1001; }
}

