/* li
{
  font-size:13px ;
}
h5
{
  color: #1976D2;
} */
ol.alphabetic-list {
  list-style-type: lower-alpha;
}
#next{
  color: white;
  background-color: #1976D2;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #1976D2;

  padding: 7px;
  font-size: 14px;
}
#next:hover{
  background:gray;
  color: white;
 }