
  /* Add this to your CSS */
  .img-resize {
    width:  auto !important;
    height: 150px !important;
    object-fit: contain;
  }
  
 /* Add this to your CSS */
.img-setting {
    width: auto !important;
    height: 150px !important;
    object-fit: contain;
  }
  
  

.question__content__box {
    min-height: 500px;overflow-y: auto;
}

@media (max-width: 575px) {
    .question__content__box {
        min-height: 350px; 
    }

}

.border-color { border-color: #eee !important; }

.scroller * { scrollbar-width: thin;scrollbar-color: #ddd #eee; }
.scroller *::-webkit-scrollbar { width: 12px; } 
.scroller *::-webkit-scrollbar-track { background: #eee; }
.scroller *::-webkit-scrollbar-thumb { background-color: #ddd;border-radius: 20px;border: 3px solid #eee; }

.collapse__btn .icon .bi-dash-circle { display: none; }
.collapse__btn:not(.collapsed) .icon .bi-plus-circle { display: none; }
.collapse__btn:not(.collapsed) .icon .bi-dash-circle { display: block;color: var(--brand); }

.inner__accordion .accordion-button.blue { background: #E6EFF2;color: var(--dark); }

@media (max-width: 1199px) {
  .col.instructions-top-containe { width: 100%; }
}

.form-check-label p { margin-bottom: 0 !important; }
.question__option__item input[type="radio"] { border-color: #333; }
.question__option__item input[type="radio"]:checked { border-color: var(--blue); }
 